import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "home-table",
  id: "home-table-pagination"
}
const _hoisted_2 = { class: "home-table-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExportOutlined = _resolveComponent("ExportOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('75652')), 1),
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: $setup.onExport,
        loading: $props.exportLoading
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_ExportOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.$t('76182')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "loading"])
    ]),
    _createVNode(_component_a_table, {
      columns: _ctx.columns,
      "data-source": $props.list,
      scroll: { x: 1700 },
      sticky: "",
      scrollToFirstRowOnChange: "",
      pagination: false
    }, {
      bodyCell: _withCtx(({ column, record }) => [
        (column.key === 'total_price')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString($setup.formatCommasFixed(record.total_price, 2)), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'redeemed_total_price')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString($setup.formatCommasFixed(record.redeemed_total_price, 2)), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'refund_total_price')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString($setup.formatCommasFixed(record.refund_total_price, 2)), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'available_total_price')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createTextVNode(_toDisplayString($setup.formatCommasFixed(record.available_total_price, 2)), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      headerCell: _withCtx(({ column }) => [
        (column.key === 'total_price')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('75672')) + _toDisplayString(`(${$props.currency})`), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'redeemed_total_price')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('75674')) + _toDisplayString(`(${$props.currency})`), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'refund_total_price')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('75676')) + _toDisplayString(`(${$props.currency})`), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'available_total_price')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('75678')) + _toDisplayString(`(${$props.currency})`), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createVNode(_component_a_pagination, {
      size: "small",
      total: $props.pagination.total,
      "show-total": (total, range) => _ctx.$t('76592',{
        start:range[0],
        end:range[1],
        count:total,
      }),
      "show-size-changer": "",
      "show-quick-jumper": "",
      current: $props.pagination.current,
      pageSize: $props.pagination.pageSize,
      onChange: $setup.onChange
    }, null, 8, ["total", "show-total", "current", "pageSize", "onChange"])
  ]))
}