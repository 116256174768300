import numbro from 'numbro';
/**
 * 根据所传固定值保留位数，将金额逗号隔开
 * @param value
 * @param fixed
 */
const formatCommasFixed = (value: string | number, fixed = 2) => {
    if (!value && value !== 0) {
        return '';
    }
    return numbro(value).format({
        thousandSeparated: true,
        mantissa: fixed,
    });
};

export { formatCommasFixed };
