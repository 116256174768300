
import { formatCommasFixed } from '@/utils/math'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    res: Object
  },
  setup() {
    return {
      formatCommasFixed
    }
  }
})
