import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42ab6864"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-view" }
const _hoisted_2 = { class: "text-view-title" }
const _hoisted_3 = { class: "box" }
const _hoisted_4 = { class: "box-item midMarginR" }
const _hoisted_5 = { class: "box-item-title" }
const _hoisted_6 = { class: "box-item-num" }
const _hoisted_7 = { class: "box-item-count" }
const _hoisted_8 = { class: "box-item midMarginR" }
const _hoisted_9 = { class: "box-item-title" }
const _hoisted_10 = { class: "box-item-num" }
const _hoisted_11 = { class: "box-item-count" }
const _hoisted_12 = { class: "box-item midMarginR" }
const _hoisted_13 = { class: "box-item-title" }
const _hoisted_14 = { class: "box-item-num" }
const _hoisted_15 = { class: "box-item-count" }
const _hoisted_16 = { class: "box-item" }
const _hoisted_17 = { class: "box-item-title" }
const _hoisted_18 = { class: "box-item-num" }
const _hoisted_19 = { class: "box-item-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('75651')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('75659')) + " (" + _toDisplayString(_ctx.res?.currency) + ")", 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatCommasFixed(_ctx.res?.total_price)), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('75660')) + ": " + _toDisplayString(_ctx.res?.unit_count), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('75661')) + " (" + _toDisplayString(_ctx.res?.currency) + ")", 1),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.formatCommasFixed(_ctx.res?.redeemed_total_price, 2)), 1),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('75662')) + ": " + _toDisplayString(_ctx.res?.redeemed_unit_count), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('75663')) + " (" + _toDisplayString(_ctx.res?.currency) + ")", 1),
        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.formatCommasFixed(_ctx.res?.refund_total_price, 2)), 1),
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('75664')) + ": " + _toDisplayString(_ctx.res?.refund_unit_count), 1)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('75665')) + " (" + _toDisplayString(_ctx.res?.currency) + ")", 1),
        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.formatCommasFixed(_ctx.res?.available_total_price, 2)), 1),
        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('75666')) + ": " + _toDisplayString(_ctx.res?.available_unit_count), 1)
      ])
    ])
  ]))
}